@import '@ng-select/ng-select/themes/default.theme.css';/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Oswald:wght@200..700&display=swap');

body {
  font-family: "Montserrat", sans-serif !important;
 }

 h1,h1,h3,h4,h5,h6 {
  font-family: "Oswald", sans-serif !important;
 }

 .btn-rounded-full {
  border-radius: 100px !important;
 }

.form-control {
  line-height: 1.8;
}